import React from "react"
import {Row, Col, Container, Jumbotron} from "react-bootstrap"
import SEO from "../components/seo"
import CovidMessage from "../components/covidMessage";
import Layout from "../components/layout";
import {graphql} from "gatsby";
import CTAForm from "../components/ctaForm";
import Slider from "react-slick";
import Helmet from "react-helmet"

const IndexPage = ({data}) => {

    let carsSettings = {
        cssEase: "linear",
        arrows: true,
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        initialSlide: 0,
        centerPadding: "50px",
        autoplay: true,
        autoplaySpeed: 5000,
        responsive: [
          {
            breakpoint: 1200,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
            }
          },
          {
            breakpoint: 576,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            }
          },
        ]
    };

    return (
        <Layout pageInfo={{pageName: "index"}}>

            <Helmet>
                <script>
                    {`
                        document.addEventListener('DOMContentLoaded', function() {
                            const trustpilot_invitation = {
                                recipientEmail: 'kieron@icashcars.co.uk',
                                recipientName: 'icashcars',
                                referenceId: 'order',
                                source: 'InvitationScript',
                            };
                            tp('createInvitation', trustpilot_invitation);
                        });
                    `}
                </script>
                <script type="text/javascript" src="//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js" async></script>
            </Helmet>

            <SEO title="Sell My Car Online | Buy My Car" description="Do you want to sell your car quickly? We come to you offering the best prices and hassle free transaction! Get in touch today."/>

            <CovidMessage></CovidMessage>

            <Jumbotron id="home" className="bg-primary pt-lg-7 pb-lg-6 text-center mb-0 bg-tyre px-0">
                <Container className="position-relative">
                    <h1 className="display-2 lp-header font-weight-700 mb-4 text-white text-center">Sell Your Car</h1>
                    <CTAForm CTAButtonText={data?.home?.home_page_main?.ctabuttontext}></CTAForm>

                    <Row className="mt-6">
                        <Col lg={3} className="mb-4 mb-lg-0">
                            <Row>
                                <Col xs={12} className="text-left">
                                    <h3 className="h4 font-weight-900 text-white mb-2">{data?.home?.page_header?.feature1Title ?? ""}</h3>
                                    <p className="text-white mb-0">
                                        {data?.home?.page_header?.feature1Text ?? ""}
                                    </p>
                                </Col>
                            </Row>
                        </Col>
                        <Col lg={3} className="mb-4 mb-lg-0">
                            <Row>
                                <Col xs={12} className="text-left">
                                    <h3 className="h4 font-weight-900 text-white mb-2">{data?.home?.page_header?.feature2Title ?? ""}</h3>
                                    <p className="text-white mb-0">
                                        {data?.home?.page_header?.feature2Text ?? ""}
                                    </p>
                                </Col>
                            </Row>
                        </Col>
                        <Col lg={3} className="mb-4 mb-lg-0">
                            <Row>
                                <Col xs={12} className="text-left">
                                    <h3 className="h4 font-weight-900 text-white mb-2">{data?.home?.page_header?.feature3Title ?? ""}</h3>
                                    <p className="text-white mb-0">
                                        {data?.home?.page_header?.feature3Text ?? ""}
                                    </p>
                                </Col>
                            </Row>
                        </Col>
                        <Col lg={3} className="mb-4 mb-lg-0">
                            <Row>
                                <Col xs={12} className="text-left">
                                    <h3 className="h4 font-weight-900 text-white mb-2">{data?.home?.page_header?.feature4Title ?? ""}</h3>
                                    <p className="text-white mb-0">
                                        {data?.home?.page_header?.feature4Text ?? ""}
                                    </p>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </Jumbotron>

            <Container className="home-step mt-6 mb-3" id="howtosell">
                <h2 className="home_title text-primary-dark font-weight-800 letter-spacing-n2">How to sell your car with iCashCars</h2>
                <ul className="steps-list mt-3 justify-content-lg-start justify-content-sm-center">
                    {data?.home?.steps?.steps?.map((step, i) => (
                        <li className="step-item mb-4 col-lg-4 col-sm-10">
                            <div className="step-img-wrapper">
                                <p className="step-name zindex-100 text-gray font-weight-900">Step <span className="text-primary-dark">{step.step ?? i}</span></p>
                                {<img className="step-img" src={step.stepImage?.mediaItemUrl ?? ""} alt={step.stepImage?.altText ?? ""}/>}
                            </div>
                            <div className="step-text mt-3">
                            <p className="mb-1">{step.text ?? ""}</p>
                        </div>
                        </li>
                    )) ?? ""}
                </ul>
            </Container>

            <div className="grey-back pt-6 pb-6">
                <Container>
                    <h2 className="home_title-md text-center font-weight-800 text-primary-dark letter-spacing-n2"><span className="text-dark">iCashCars</span> {data?.home?.home_page_main?.heroBlock}<span className="text-dark">.</span></h2>
                </Container>
            </div>

            <Container className="pt-6">
                <h2 className="home_title-md font-weight-800 text-primary-dark letter-spacing-n2 mb-4"><nobr>We Buy<span className="text-dark">.</span></nobr> <nobr>We Sell<span className="text-dark">.</span></nobr> <nobr>We Broker<span className="text-dark">.</span></nobr></h2>
            </Container>
            <div className="cars-wrapper pb-6">
                <Slider {...carsSettings} className="cars-list">
                    {data?.home?.home_page_main?.cars.reverse().map((car, i) => (
                        <div className="car-inner">
                            <a href="https://uk.trustpilot.com/review/icashcars.co.uk">
                            <div className="car-item">
                                <div className="car-back">
                                    <div className="car-img">
                                            <img alt="" src={car.image?.mediaItemUrl ?? ""} />
                                    </div>
                                </div>
                                <div className="car-context ml-3">
                                    <div className="car-logo">
                                        <img className="" alt="" src={car?.logo?.mediaItemUrl ?? ""} />
                                    </div>
                                    <p className="car-status text-primary-dark font-weight-800">Sold<span className="text-dark">.</span></p>
                                    <p className="car-name">
                                        <span className="car-brand font-weight-800">{car.make}&nbsp;</span>
                                        <span className="car-model">{car.model}</span>
                                    </p>
                                    <p className="car-info">
                                        <span className="car-plate">{car.plate}-plate&nbsp;</span>
                                        <span className="car-mileage">{car.mileage} miles</span>
                                    </p>
                                </div>
                            </div>
                            </a>
                        </div>
                    )) ?? ""}
                </Slider>
            </div>

            <div className="grey-back pt-6 pb-6">
                <Container>
                    <h3 style={{textAlign: 'center'}}>We consider any make and any model of car.</h3>
                    <ul className="sponsors-list">
                        {data?.home?.home_page_main?.logos?.map((item, i) => (
                            <li className="sponsors-item">
                                <img className="sponsors-img" alt="" src={item?.logo?.mediaItemUrl ?? ""} />
                            </li>
                        ))}

                    </ul>
                </Container>
            </div>

            <div className="trust-pilot-wrapper">
                <div class="trustpilot-widget" data-locale="en-GB" data-template-id="54ad5defc6454f065c28af8b" data-businessunit-id="5f426bbd8956b100010352af" data-style-height="240px" data-style-width="100%" data-theme="light" data-stars="1,2,3,4,5" data-review-languages="en">
                    <a href="https://uk.trustpilot.com/review/icashcars.co.uk" target="_blank" rel="noopener">Trustpilot</a>
                </div>
            </div>
        </Layout>
    )
};

export const data = graphql`
    query IndexQuery($homeID: String! = "cG9zdDozNg=="){
        home: wpHomes(id: {eq: $homeID}) {
            home_page_main {
              ctabuttontext
              logos {
                logo {
                  mediaItemUrl
                }
              }
              cars {
                make
                model
                plate
                mileage
                logo {
                  mediaItemUrl
                }
                image {
                  mediaItemUrl
                }
              }
              trustPilot {
                reviews {
                  date
                  review
                  stars
                  title
                }
                stars
                title
              }
              heroBlock
            }
            page_header {
                feature1Text
                feature1Title
                feature2Text
                feature2Title
                feature3Text
                feature3Title
                feature4Text
                feature4Title
                fieldGroupName
                pageTitle
            }
            steps {
                steps {
                    step
                    stepImage {
                        altText
                        mediaItemUrl
                    }
                    text
                }
            }
        }

        car0: file(relativePath: { eq: "cars/fiat.jpeg" }) {
            childImageSharp {
                fixed {
                      ...GatsbyImageSharpFixed
                }
            }
        }
        car1: file(relativePath: { eq: "cars/fiat.jpeg" }) {
            childImageSharp {
                fixed {
                      ...GatsbyImageSharpFixed
                }
            }
        }
        car2: file(relativePath: { eq: "cars/hyundai.jpeg" }) {
            childImageSharp {
                fixed {
                      ...GatsbyImageSharpFixed
                }
            }
        }
        car3: file(relativePath: { eq: "cars/smart.jpeg" }) {
            childImageSharp {
                fixed {
                      ...GatsbyImageSharpFixed
                }
            }
        }
        car4: file(relativePath: { eq: "cars/mini.jpeg" }) {
            childImageSharp {
                fixed {
                      ...GatsbyImageSharpFixed
                }
            }
        }
        car5: file(relativePath: { eq: "cars/car1.jpg" }) {
            childImageSharp {
                fixed {
                      ...GatsbyImageSharpFixed
                }
            }
        }
        carlogo1: file(relativePath: { eq: "cars/carlogo1.png" }) {
            childImageSharp {
                fixed {
                      ...GatsbyImageSharpFixed
                }
            }
        }
        sponsor1: file(relativePath: { eq: "sponsors/sponsor1.png" }) {
            childImageSharp {
                fluid {
                      ...GatsbyImageSharpFluid
                }
            }
        }
        sponsor2: file(relativePath: { eq: "sponsors/sponsor2.png" }) {
            childImageSharp {
                fluid {
                      ...GatsbyImageSharpFluid
                }
            }
        }
        sponsor3: file(relativePath: { eq: "sponsors/sponsor3.png" }) {
            childImageSharp {
                fluid {
                      ...GatsbyImageSharpFluid
                }
            }
        }
        sponsor4: file(relativePath: { eq: "sponsors/sponsor4.png" }) {
            childImageSharp {
                fluid {
                      ...GatsbyImageSharpFluid
                }
            }
        }
        sponsor5: file(relativePath: { eq: "sponsors/sponsor5.png" }) {
            childImageSharp {
                fluid {
                      ...GatsbyImageSharpFluid
                }
            }
        }

      iconCheck: file(relativePath: { eq: "icons/icon-check.png" }) {
          childImageSharp {
            fixed {
                    ...GatsbyImageSharpFixed
              }
          }
      }
      iconGlass: file(relativePath: { eq: "icons/icon-glass.png" }) {
          childImageSharp {
            fixed {
                    ...GatsbyImageSharpFixed
              }
          }
      }
      iconHand: file(relativePath: { eq: "icons/icon-hand.png" }) {
          childImageSharp {
            fixed {
                    ...GatsbyImageSharpFixed
              }
          }
      }

      iconDb: file(relativePath: { eq: "icons/icon-db.png" }) {
          childImageSharp {
            fixed {
                    ...GatsbyImageSharpFixed
              }
          }
      }
      iconWallet: file(relativePath: { eq: "icons/icon-wallet.png" }) {
          childImageSharp {
            fixed {
                    ...GatsbyImageSharpFixed
              }
          }
      }
      iconHandshake: file(relativePath: { eq: "icons/icon-handshake.png" }) {
          childImageSharp {
            fixed {
                    ...GatsbyImageSharpFixed
              }
          }
      }
      iconPaper: file(relativePath: { eq: "icons/icon-paper.png" }) {
          childImageSharp {
            fixed {
                    ...GatsbyImageSharpFixed
              }
          }
      }
        iconCar: file(relativePath: { eq: "icons/icon-car.png" }) {
          childImageSharp {
            fixed {
                    ...GatsbyImageSharpFixed
              }
          }
        }
    }
`

export default IndexPage
